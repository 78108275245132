.chat-footer .chat-input .chat-form {
  display: flex;
  align-items: center;
  position: relative;
}

.mail-write-box {
  flex-grow: 1; /* Takes up available space */
  padding-right: 80px; /* Adjust based on button and image size */
}

.file-input-label {
  margin-right: 10px; /* Adjust this value as needed */
  position: absolute;
  right: 60px; /* Adjust based on button size */
  cursor: pointer;
}

.send-button {
  margin-left: 10px; /* Adjust this value as needed */
  position: absolute;
  right: 10px; /* Adjust as needed */
  border-radius: 12px;
  /* Add more styling for the button */
}

.message-image {
  max-width: 100%;
  height: auto;
  width: 300px;
  display: block; /* Ensures the image doesn't have extra space below it */
  border-radius: 8px; /* Optional, for rounded corners */
}
.imageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  border: 1px solid #ebebeb;
  padding: 8px;
  gap: 8px;
  width: calc(100% - 28px);
  border-radius: 4px;
}
.imagePicker {
  width: 100px;
  height: 100px;
  display: flex;
  border: 1px solid #ebebeb;
  align-items: center;
  justify-content: center;
  background-color: #f6f7fb;
  overflow: hidden;
  cursor: pointer;
  border-radius: 50%;
  transition: ease-in-out all 0.2s;
}